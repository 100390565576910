export default ($) => ({
  thumbvideo(){
  	$('.video .thumb-video .ic-play').click(function(){
        $(this).parents('.video').find('.wrap-yt').show();
        $(this).parents('.video').find('.wrap-yt').each(function() {
            var iframe = $(this).find('iframe');
            setTimeout(function() {
                iframe[0].src = iframe.attr('src').replace('&autoplay=0', '&autoplay=1');
            }, 100);
        });
    });
  }
})