import $ from 'jquery'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import video from './libs/playvideo.js'
import { gsap, TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger } from "gsap/all"
import responsiveImage from './libs/responsive-images'

gsap.registerPlugin(TweenLite, TweenMax, MotionPathPlugin, ScrollToPlugin, ScrollTrigger);

const home = {};

video($).thumbvideo()


home.slider = function () {

  const mainslider = $('.banner-home .wrap-flex');

  mainslider.slick({
    arrows: true,
    fade: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnFocus: true,
    pauseOnHover: true,
  })

  const opt1 = {
    arrows: false,
    fade: true,
    autoplaySpeed: 3000,
    asNavFor: '.banner-home .slick-current .slider-thumb',
    autoplay: true,
  }

  const opt2 = {
    arrows: false,
    asNavFor: '.banner-home .slick-current .slider-big',
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    focusOnSelect: true,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      }
    ]
  }

  const sliderBig = $('.banner-home .slick-current .slider-big')
  const bannerthumb = $('.banner-home .slick-current .slider-thumb')

  sliderBig.slick(opt1)
  bannerthumb.slick(opt2)
  $('.slider-box').each(function () {

    if ($(this).find('.slider-thumb').parents().hasClass('slick-current')) {
      $('.banner-home .slider-thumb').on('afterChange', function (event, slick, currentSlide) {
        const sum = $('.banner-home  .slick-current .slider-thumb .list').length
        if (currentSlide == sum - 1) {
          setTimeout(function () {

            mainslider.slick('slickNext')
            sliderBig.slick('destroy')
            bannerthumb.slick('destroy')
            // 
            $('.banner-home .slick-current .slider-big').slick(opt1)
            $('.banner-home .slick-current .slider-thumb').slick(opt2)

          }, 3000)
        }
      })
    }
    $('.banner-home .wrap-flex > .slick-arrow,.banner-home .wrap-flex > .slick-dots li button').on('click', function () {
      sliderBig.slick('destroy')
      bannerthumb.slick('destroy')
      setTimeout(function () {
        $('.banner-home .slick-current .slider-big').slick(opt1)
        $('.banner-home .slick-current .slider-thumb').slick(opt2)
      }, 1000)

    })

  })

}

home.sliderTech = function () {

  var slider = $('.slider-tech-text')
  var currSlider = $('.indicator-slide .slide-nav .currSlide')
  var totSlider = $('.indicator-slide .slide-nav .totSlide')
  var countSlider = $('.slider-tech-text .list').length;

  if (countSlider < 10) { countSlider = "0" + countSlider; }
  totSlider.html("/" + countSlider);

  $('.slider-tech-big').slick({
    arrows: false,
    asNavFor: '.slider-tech-text',
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnFocus: false,
    pauseOnHover: false,
    focusOnSelect: true
  })
  $('.slider-tech-text').slick({
    nextArrow: $('.indicator-slide .arrow-next'),
    prevArrow: $('.indicator-slide .arrow-prev'),
    fade: true,
    asNavFor: '.slider-tech-big',
    focusOnSelect: true
  })

  slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    var id = nextSlide + 1;
    if (id < 10) { id = "0" + id; }
    currSlider.html(id);
  });
}
home.paggingSection = function () {

  var act = function (top) {
    var ctop, cbottom, section, menu;
    $(".trigger").each(function () {
      section = $(this);
      ctop = section.offset().top - ($(window).height() / 2);
      cbottom = (section.offset().top + section.outerHeight()) - ($(window).height() / 2);
      if (ctop <= top && cbottom >= top) {
        menu = section.attr('id').split(' ');
        if (!$(".de-paging-section a[content-target=" + menu[menu.length - 1] + "]").hasClass('active')) {
          $(".de-paging-section a").removeClass('active');
          $(".de-paging-section").removeClass('section-white');
          $(".de-paging-section a[content-target=" + menu[menu.length - 1] + "]").addClass('active');
        } else if ($(".de-paging-section a[content-target=" + menu[menu.length - 1] + "]").hasClass('white')) {
          $(".de-paging-section").removeClass('section-white');
          $(".de-paging-section a[content-target=" + menu[menu.length - 1] + "]").parent().addClass('section-white');
        }
      }
    });
  };

  $(".de-paging-section a").on('click', function (e) {
    e.preventDefault();

    // console.log(`#${$(this).attr('content-target')}`); 
    // var target = $(".trigger[id-target=" + $(this).attr('content-target') + "]").offset().top - 140;

    var target = $(`#${$(this).attr('content-target')}`).offset().top - $('header').height();
    gsap.to($(window), 1.2, {
      scrollTo: {
        y: target
      },
      ease: "Power2.easeOut",
      overwrite: 5
    });
  });

  var lastScrollTop = 0
  $(window).scroll(function () {
    var direction = "down",
      scrolltop = $(window).scrollTop();
    if (scrolltop < lastScrollTop) {
      direction = "up";
    }
    act(scrolltop);
    lastScrollTop = scrolltop;
  });
}

home.init = function () {
  // this.slider()
  this.sliderTech()
  this.paggingSection()
}

home.init()